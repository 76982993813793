<template>
  <v-container class="pa-0">
    <v-row :style="{ height: '100vh' }" class="justify-center align-center ma-auto">
      <v-col cols="12">
        <v-sheet max-width="1000" class="mx-auto">
          <v-card elevation="6">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <router-link :to="{ name: 'home' }">
                  <v-img @contextmenu.prevent class="mx-auto" src="@/assets/logo.svg" width="180" height="100"></v-img>
                </router-link>
                <v-card-text class="text-white text-center">
                  <p class="text-h6 text-center text-primary">
                    Beli beragam jenis tiket cepat dan mudah
                    <strong>hanya di e-tix</strong>
                  </p>
                </v-card-text>

                <v-img @contextmenu.prevent class="mx-auto" src="@/assets/01_ilustration.svg" width="350"
                  height="350"></v-img>
              </v-col>

              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-card-text>
                  <ErrorHandling class="mr-10 my-2" v-if="error" :error="error" />
                  <p class="text-h6 text-primary font-weight-bold">Daftar</p>
                  <v-divider class="my-2"></v-divider>
                  <v-row align="center">
                    <v-col cols="12" sm="11">
                      <v-form class="mt-2" ref="signup" v-model="valid" lazy-validation>
                        <v-text-field variant="outlined" @keyup.enter="handleSubmit()" v-model="input.full_name"
                          :rules="rules.full_name" @keypress="filterName(event)" label="Nama lengkap" outlined dense
                          color="primary" class="my-3" required />
                        <v-text-field variant="outlined" @keyup.enter="handleSubmit()" v-model="input.email"
                          :rules="rules.email" :error-messages="inputError.email" @keypress="inputError.email = ''"
                          label="Email" type="email" outlined dense color="primary" class="my-3" required
                          hint="Daftar akun dengan emailmu yang aktif karena tiket akan dikirim ke email tersebut"
                          persistent-hint />
                        <!-- <v-text-field variant="outlined" @keyup.enter="handleSubmit()" v-model="input.phone"
                          :rules="rules.phone" :error-messages="inputError.phone"
                          @keypress="filter(event), (inputError.phone = '')" label="Nomor whatsapp" prefix="+62"
                          persistent-hint type="tel" outlined dense color="primary" class="my-3" required /> -->
                        <phone-number v-model="input.phone"></phone-number>
                        <v-text-field variant="outlined" @click.right.prevent @copy.prevent @paste.prevent
                          @keyup.enter="handleSubmit()" v-model="input.password" :rules="rules.password"
                          hint="Password minimal 8 digit gabungan huruf besar, huruf kecil, angka dan simbol"
                          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                          @click:append-inner="visible = !visible" :type="visible ? 'text' : 'password'"
                          label="Password" outlined dense color="primary" class="my-3" required />
                        <v-text-field variant="outlined" @click.right.prevent @copy.prevent @paste.prevent
                          @keyup.enter="handleSubmit()" v-model="input.retype_password" :rules="rules.retype_password"
                          label="Konfirmasi password   " hint="Ketik ulang passwordmu" outlined dense color="primary"
                          type="password" class="my-3" required />

                        <v-btn :loading="isProcessing" :disabled="isProcessing || !valid" @click.prevent="handleSubmit"
                          class="text-capitalize" :color="isProcessing || !valid ? 'grey' : 'primary'"
                          block>Daftar</v-btn>

                        <v-card-text>
                          <h6 class="text-center text-grey text-caption">
                            Sudah punya akun?
                            <v-btn class="text-none pa-0 font-weight-bold text-caption" size="x-small" color="primary"
                              variant="text" to="/login">Yuk, masuk!</v-btn>
                          </h6>
                        </v-card-text>
                      </v-form>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <footer-copyright />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

  <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn color="pink" rounded="pill" variant="text" @click="snackbar.status = false">
        Tutup
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import isEmail from "validator/lib/isEmail";
import { isValidPhoneNumber } from 'libphonenumber-js'
import isStrongPassword from "validator/lib/isStrongPassword";
import ErrorHandling from "@/components/Error.vue";
import FooterCopyright from "@/components/FooterCopyright.vue";
import PhoneNumber from "@/components/input/PhoneNumber.vue";

export default {
  name: "RegisterView",

  components: {
    ErrorHandling,
    FooterCopyright,
    PhoneNumber
  },

  data() {
    return {
      step: 1,
      visible: false,
      input: {
        full_name: "",
        phone: "",
        email: "",
        password: "",
        retype_password: "",
      },
      inputError: {
        email: "",
        phone: [],
      },
      rules: {
        full_name: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        phone: [
          (v) => !!v || "Kamu perlu mengisi bagian ini",
          (v) => isValidPhoneNumber(`+${v}`) || "Nomor whatsapp tidak valid",
          (v) =>
            (v.length >= 10 && v.startsWith("8")) ||
            "Nomor whatsapp terdiri atas minimal 10 digit dan dimulai dari angka 8",
        ],
        email: [
          (v) => !!v || "Kamu perlu mengisi bagian ini",
          (v) => isEmail(v) || "Tolong masukkan inputan yang benar",
        ],
        password: [
          (v) => !!v || "Password wajib diisi",
          (v) => (v && v.length >= 8) || "Password kurang dari 8 karakter",
          (v) =>
            isStrongPassword(v) ||
            "Password harus terdiri atas huruf kapital, huruf kecil, simbol, dan angka",
        ],
        retype_password: [
          (v) => !!v || "Konfirmasi password perlu diisi",
          (v) => v === this.input.password || "Konfirmasi password-mu salah",
        ],
      },
      encryptedPassword: null,
      error: "",
      isProcessing: false,
      valid: false,
      snackbar: {
        status: false,
        text: "",
      },
    };
  },

  methods: {
    filterName: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[a-zA-Z][a-zA-Z\s]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    encryptPassword() {
      const encJson = this.$CryptoJS.AES.encrypt(
        JSON.stringify(this.password),
        this.salt
      ).toString();
      const encryptedPass = this.$CryptoJS.enc.Base64.stringify(
        this.$CryptoJS.enc.Utf8.parse(encJson)
      );
      this.encryptedPassword = encryptedPass;
    },

    async isPhoneRegistered(phoneNumber) {
      try {
        await this.axios.post("user/phone", {
          phone_number: phoneNumber,
        });

        return true;
      } catch (error) {
        switch (error?.response?.status) {
          case 420: {
            return false;
          }
        }
      }
    },
    async isEmailRegistered(email) {
      try {
        await this.axios.post("user/email", {
          user_email: email,
        });

        return true;
      } catch (error) {
        switch (error?.response?.status) {
          case 420: {
            return false;
          }
        }
      }
    },

    test() {
      this.$store.state.credential = this.input.email;
      this.$router.push({ name: "email-verification" });
    },

    async register() {
      const fullName = this.input.full_name.split(" ");
      var firstName = fullName[0];
      var lastName = fullName.slice(1).join(" ");

      lastName = lastName || firstName;

      await this.axios
        .post(
          "user/register",
          {
            user_firstname: firstName,
            user_lastname: lastName,
            user_email: this.input.email,
            password: this.input.password,
            user_phone: `${this.input.phone[0]}${this.input.phone[1]}`,
            retype_password: this.input.retype_password,
            user_gender: 2,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            this.$store.state.credential = this.input.email;
            this.$router.push({ name: "email-verification" });
          } else {
            this.error = response.data.message;
          }
        })
        .catch((error) => {
          if (error.response.data.error) {
            this.error = error.response.data.message;
          }
        });
    },

    async handleSubmit() {
      const { valid } = await this.$refs.signup.validate();

      const email = await this.isEmailRegistered(this.input.email);
      const phone = await this.isPhoneRegistered(`${this.input.phone[0]}${this.input.phone[1]}`);

      console.log(email, phone);

      if (phone) {
        this.inputError.phone =
          "Nomor whatsapp sudah terdaftar silahkan mendaftar dengan nomor whatsapp lainnya";
      }

      if (email) {
        this.inputError.email =
          "Alamat email sudah terdaftar, silahkan mendaftar dengan alamat email lainnya";
      }

      if (valid && !email && !phone) {
        this.isProcessing = true;
        // this.encryptPassword()
        // console.log(this.encryptedPassword)
        this.register();
        setTimeout(() => (this.isProcessing = false), 2000);
      }
    },

    async login() {
      await this.axios
        .post(
          "auth/login",
          {
            email: this.input.email,
            password: this.input.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.$store.dispatch("login", response.data.response);
          this.$router.go("/");
        })
        .catch((error) => {
          if (error.response.data.error) {
            this.error = error.response.data.message;
          }
        });
    },

    showSnackbar(text) {
      this.snackbar.status = true;
      this.snackbar.text = text;
    },
  },
};
</script>
