<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn variant="tonal" color="primary" :to="{ name: 'order' }">
          <v-icon>mdi-chevron-left</v-icon>
          Kembali
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="loading">
      <v-col>
        <div class="d-flex justify-center align-center w-100">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
      </v-col>
    </v-row>
    <v-row v-else-if="!loading && !transaction">
      <error :width="500" type="no_product" title="Maaf, Kami Tidak Menemukan Yang Kamu Cari"
        text="Silahkan ketik kata kunci lainnya." />
    </v-row>

    <template v-else>
      <v-row class="mb-5">
        <v-col>
          <v-card variant="outlined">
            <v-card-item>
              <div class="d-flex justify-end">
                <div>Transaction ID : {{ transaction?.transaction_id }}</div>
              </div>
            </v-card-item>

            <v-divider color="black"></v-divider>

            <v-card-text>
              <v-row class="pa-3">
                <v-col>
                  <div>Total Tiket</div>
                  <div>{{ totalTicket }} Tiket</div>
                </v-col>
                <v-col>
                  <div>Dibayar Pada</div>
                  <div v-if="transaction.payment">
                    {{
                      utcToLocale(
                        transaction.payment?.payment_timestamp,
                        "DD/MM/YYYY, HH:mm"
                      )
                    }}
                  </div>
                  <div v-else>-</div>
                </v-col>
                <v-col>
                  <div>Metode Pembayaran</div>
                  <div v-if="transaction.payment">
                    {{ transaction.payment?.payment_name }}
                  </div>

                  <div v-else>-</div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider color="black"></v-divider>

            <v-card-text>
              <v-row>
                <v-col> Total Pembayaran </v-col>
                <v-col>
                  <div class="d-flex justify-end align-center">
                    <div class="me-2 font-weight-bold" v-if="transaction.payment">
                      {{
                        numberToRupiah(
                          parseInt(transaction.payment?.payment_total)
                        )
                      }}
                    </div>
                    <div v-else>-</div>
                    <v-icon v-if="transaction.payment" @click="expandPayment = !expandPayment">{{
                      expandPayment ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-expand-transition>
              <div v-show="expandPayment">
                <v-divider color="black"></v-divider>

                <v-card-text>
                  <template v-for="(order, index_o) in transaction.order" :key="index_o">
                    <template v-for="(orderDetail, index_od) in order.order_details" :key="index_od">
                      <div class="d-flex justify-space-between my-3">
                        <div>
                          {{ orderDetail.product.product_name }} -
                          {{ orderDetail.product.producttype_name }}
                        </div>

                        <div class="text-end">
                          {{
                            numberToRupiah(
                              parseFloat(
                                orderDetail.product.producttype_price
                              ) * parseInt(orderDetail.qty)
                            )
                          }}
                        </div>
                      </div>
                    </template>
                  </template>

                  <div class="d-flex justify-space-between my-3">
                    <div>Diskon</div>

                    <div class="text-end">{{ numberToRupiah(totalDiscount) }}</div>
                  </div>

                  <div class="d-flex justify-space-between my-3">
                    <div>Biaya Transaksi</div>

                    <div class="text-end" v-if="transaction.payment">
                      {{ numberToRupiah(transaction.payment.payment_fee) }}
                    </div>
                  </div>

                  <div class="d-flex justify-space-between my-3">
                    <div>Biaya Platform</div>

                    <div class="text-end">{{ numberToRupiah(transaction.payment?.service_fee) }}</div>
                  </div>

                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <template v-for="(product, index) in detailOrder" :key="index">
            <v-card class="mb-5" variant="outlined">
              <v-card-item>
                <v-row>
                  <v-col>
                    <div class="font-weight-bold">
                      {{ product.product_name }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-item>

              <v-divider color="black"></v-divider>

              <v-card-text>
                <v-row>
                  <v-col cols="12" xxl="3" xl="3" lg="3" md="3" sm="3">
                    <v-img :src="product.product_details[0].productdetail_image_url" class="rounded-lg"></v-img>
                  </v-col>

                  <v-col cols="12" xxl="9" xl="9" lg="9" md="9" sm="9">
                    <template v-for="(productType, index_pt) in product.product_types" :key="index_pt">
                      <div>
                        <div class="font-weight-bold mb-3">
                          {{ productType.producttype_name }}
                        </div>

                        <div>
                          <v-row>
                            <v-col v-if="false">
                              <div>Waktu Kunjungan:</div>
                              <div>24 Mei 2023</div>
                            </v-col>

                            <v-col class="d-flex justify-start">
                              <div>
                                <div>Jumlah Tiket</div>
                                <div>{{ productType.qty }} Tiket</div>
                              </div>
                            </v-col>

                            <v-col class="d-flex justify-end">
                              <v-btn class="text-none" variant="tonal" color="primary"
                                v-if="transaction.transaction_status == 1">
                                Lihat E-Ticket
                                <my-order-ticket :tickets="productType.ticket" :product-type-name="productType.producttype_name
                                  "></my-order-ticket>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider color="black" class="my-3"></v-divider>
                    </template>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider color="black"></v-divider>

              <v-card-text>
                <div class="d-flex justify-space-between flex-wrap">
                  <div>
                    <v-alert variant="tonal" color="secondary" type="warning" density="compact" class="my-2">
                      Silahkan tunjukkan barcode pada E-Ticket kepada petugas
                      untuk ditukarkan
                    </v-alert>
                  </div>

                  <div class="bg-primary rounded pa-3 my-2">
                    Email : <span>{{ product.email_contact }}</span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import Error from "@/components/error/ErrorComponent.vue";
import { utcToLocale } from "@/utils/dateFormatter";
import { numberToRupiah } from "@/utils/numberFormatter";
import MyOrderTicket from "./MyOrderTicket.vue";
import moment from "moment";

export default {
  created() {
    this.transactionID = this.$route.params.id;
    this.axios
      .get(`product/transaction/${this.transactionID}`)
      .then((response) => {
        this.transaction = response.data.response;
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
  },
  components: { MyOrderTicket, Error },
  computed: {
    totalTicket() {
      return this.transaction?.order.reduce((acc, order) => {
        const totalQtyOrderDetails = order.order_details.reduce(
          (acc2, orderDetail) => acc2 + orderDetail.qty,
          0
        );
        return acc + totalQtyOrderDetails;
      }, 0);
    },
    serviceFee() {
      return Math.round(
        parseInt(this.transaction.transaction_total) * (3 / 100)
      );
    },
    totalDiscount() {
      return (
        parseInt(this.transaction?.discount || 0) +
        this.transaction?.order.reduce((acc, order) => {
          const totalDiscountOrderDetails = order.order_details.reduce(
            (acc2, orderDetail) => acc2 + parseInt(orderDetail.discount || 0),
            0
          );
          return acc + totalDiscountOrderDetails;
        }, 0)
      );
    },
    detailOrder() {
      const newOrderDetail = [];

      for (const order of this.transaction.order) {
        for (const orderDetail of order.order_details) {
          const findOrderDetail = newOrderDetail.find((element) => {
            return element.product_id == orderDetail.product.product_id;
          });

          if (findOrderDetail) {
            findOrderDetail.product_types.push({
              producttype_name: orderDetail.product.producttype_name,
              qty: orderDetail.qty,
              amount: orderDetail.amount,
              ticket: orderDetail.ticket,
            });

            findOrderDetail.qty += orderDetail.qty;
          } else {
            const data = orderDetail.product;
            data.product_types = [
              {
                producttype_name: orderDetail.product.producttype_name,
                qty: orderDetail.qty,
                amount: orderDetail.amount,
                ticket: orderDetail.ticket,
              },
            ];
            newOrderDetail.push(data);
          }
        }
      }

      return newOrderDetail;
    },
  },
  methods: {
    utcToLocale(date, format) {
      const localDate = moment.utc(date).local();
      const utcOffset = localDate.utcOffset() / 60;
      let timezone = "WIB";
      if (utcOffset === 8) timezone = "WITA";
      else if (utcOffset === 9) timezone = "WIT";

      const formatDate = `${utcToLocale(date, format)} ${timezone}`;
      return formatDate;
    },
    numberToRupiah(number) {
      return numberToRupiah(number);
    },
  },
  data() {
    return {
      transactionID: null,
      expandPayment: false,
      transaction: null,
      loading: true,
    };
  },
};
</script>

<style lang="scss" scoped></style>
